<template>
  <div class='listBox'>
    <div class="title">
      <div>
        历史订单
      </div>
      <load-more @loading="more" style="height:800px">
        <div v-if="this.orderList.length===0" style="text-align:center;line-height:800px;font-size:20px;color:#666"> 暂无历史订单</div>
        <div class="item" v-for="(item,index) in orderList" :key="item.number" :class="index==(orderList.length-1)?'borderBot':''">
          <el-row>
            <el-col :offset="2" :span="2">订单编号
            </el-col>
            <el-col :span="4" class="content" style="text-align:left">{{item.number}}
            </el-col>
            <el-col :span="2" :offset="2">订单时间
            </el-col>
            <el-col :span="4" class="content" style="text-align:left">{{item.create_time*1000|dateformat('YYYY-MM-DD HH:mm')}}
            </el-col>
            <el-col :span="3" :offset="2" style="text-align:center">订单状态
            </el-col>
            <el-col :span="3" class="content" v-if="item.state==0" style="text-align:left;font-size:20px;font-weight: 400;color:#bfbfbf;white-space: nowrap;">已关闭
            </el-col>
            <el-col :span="3" v-if="item.state==1" class="content" style="text-align:left;font-size:20px;font-weight: 400;color:red">待支付
            </el-col>
            <el-col :span="3" v-if="item.state==2" class="content" style="text-align:left;font-size:20px;font-weight: 400;color:#579ef8">已支付
            </el-col>
          </el-row>
          <div style="border-radius: 2px;overflow: hidden;margin-top:30px">
            <el-row style="background-color:#bfbfbf;height:40px;line-height:40px">
              <el-col :span="8" class="content number-title">产品/服务
              </el-col>
              <el-col :span="8" class="content number-title">数量
              </el-col>
              <el-col :span="8" class="content">小计
              </el-col>
            </el-row>
            <el-row style="background-color:#d9d9d9;height:40px;line-height:40px" v-for="oitem in item.order_items" :key="oitem.id">
              <el-col :span="8" class="content">{{oitem.name}}
              </el-col>
              <el-col :span="8" class="content">{{oitem.quantity}}
              </el-col>
              <el-col :span="8" class="content">￥{{oitem.quantity*oitem.price}}
              </el-col>
            </el-row>
            <el-row style="height:40px;line-height:40px;margin-top:10px">
              <el-col :span="19">订单详情
              </el-col>
              <el-col :span="2" class="">订单金额
              </el-col>
              <el-col :span="3" class="">￥ <span style="font-size:23px;font-weight: 500;color:red">{{item.total_price}}</span>
              </el-col>
            </el-row>
            <el-row style="height:40px;line-height:40px;" v-if="item.state==1">
              <el-col :offset="20" :span="4">
                <el-button size="mini" style="margin-left:10px" round @click="cancelOrder(item.id,index)">取消订单</el-button>
                <el-button size="mini" type="primary" style="margin-left:10px" round @click="toPay(item)">支付</el-button>
              </el-col>
            </el-row>
            <el-row style="height:40px;line-height:40px;" v-else-if="item.state==2&&(!item.invoice)">
              <el-col :offset="22" :span="4">
                <el-button size="mini" type="primary" style="margin-left:18px" round @click="billShow(item)">发票</el-button>
              </el-col>
            </el-row>
            <el-row style="height:40px;line-height:40px;" v-else-if="item.state==2&&(item.invoice.url)">
              <el-col :offset="21" :span="4">
                <el-button size="mini" type="primary" style="margin-left:18px" round @click="downbill(item.invoice.url)">下载发票</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </load-more>
    </div>
    <el-dialog title="开票信息" :visible.sync="bill" width="40%" style="height:700px">
      <el-row class="bill">
        <el-col :span="4" class="bititle">订单编号
        </el-col>
        <el-col :span="12">{{this.billInfo.number}}
        </el-col>
      </el-row>
      <el-row class="bill" style="margin-bottom:30px">
        <el-col :span="4" class="bititle">开票金额
        </el-col>
        <el-col :span="12">￥{{this.billInfo.total_price}}
        </el-col>
      </el-row>
      <el-row class="bill">
        <el-col :span="4" class="bititle">发票类型
        </el-col>
        <el-col :span="12">增值税电子普通发票
        </el-col>
      </el-row>
      <el-row class="bill">
        <el-col :span="4" class="bititle">抬头类型
        </el-col>
        <el-col :span="12">
          <el-radio-group v-model="invoiceInfo.title_type">
            <el-radio :label="2">企业</el-radio>
            <el-radio :label="1">个人或事业单位</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row class="bill" style="margin-bottom:10px">
        <el-col :span="4" class="bititle">发票抬头
        </el-col>
        <el-col :span="8">
          <el-input v-model="invoiceInfo.title" placeholder="请输入发票抬头" size="mini"></el-input>
        </el-col>
      </el-row>
      <el-row v-if="invoiceInfo.title_type==2" style="margin-bottom:10px">
        <el-col :span="4" class="bititle">税号
        </el-col>
        <el-col :span="8">
          <el-input v-model="invoiceInfo.tax_number" placeholder="请输入企业税号" size="mini"></el-input>
        </el-col>
      </el-row>
      <el-row class="bill" style="margin-bottom:10px">
        <el-col :span="4" class="bititle">电子邮箱
        </el-col>
        <el-col :span="8">
          <el-input v-model="invoiceInfo.email" placeholder="请输入电子邮箱地址" size="mini"></el-input>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelBill">取 消</el-button>
        <el-button type="primary" @click="queryBill">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { order } from '@/utils/api';
import loadMore from '@/components/loadMore';
import { isEmail } from '@/utils/verification';
import qs from 'qs';
export default {
  name:'orderlist',
  //import引入的组件需要注入到对象中才能使用
  components: { loadMore },
  data() {
    //这里存放数据
    return {
      orderList: [],
      loadvar: true,
      listProduct: [],
      par: {
        page: 1,
        page_size: 10
      },
      bill: false,
      form: {
        name: '',
        number: ''
      },
      billInfo: {},
      invoiceInfo: {
        title_type: 2,
        title: '',
        tax_number: '',
        email: ''
      },
      title_info: {
        title: '请输入抬头',
        tax_number: '请输入企业税号',
        email: '请输入电子邮箱'
      },
      index: 0
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getOrderList() {
      const res = await this.$http.get(
        order.listOrder + '?' + qs.stringify(this.par)
      );
      res.data.forEach(item => {
        item.order_items.forEach(oitem => {
          oitem.name = this.listProduct.find(
            pitem => pitem.id == oitem.product_id
          ).name;
        });
      });
      this.orderList = this.orderList.concat(res.data);
      console.log(this.orderList);
      this.par.page++;
      if (this.orderList.length === res.total) {
        this.loadvar = false;
      }
    },
    more() {
      if (!this.loadvar) {
        this.$message.info('没有更多数据了');
      } else {
        this.getOrderList();
      }
    },
    cancelOrder(id, index) {
      this.$confirm('此操作将永久取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$http.get(order.cancelOrder + '?id=' + id);
          if (res.code === 0) {
            this.orderList[index].state = 0;
            this.$forceUpdata();
            this.$message({
              type: 'success',
              message: '取消成功!'
            });
          } else {
            this.$message.error('取消失败');
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    billShow(item, index) {
      this.index = index;
      this.cloneInvoiceInfo = JSON.parse(JSON.stringify(this.invoiceInfo));
      this.billInfo = item;
      console.log(this.billInfo);
      this.bill = true;
    },
    cancelBill() {
      this.bill = false;
      this.invoiceInfo = JSON.parse(JSON.stringify(this.cloneInvoiceInfo));
    },
    async queryBill() {
      console.log(this.billInfo);
      if (this.invoiceInfo.title_type == 1) {
        if (!this.invoiceInfo.title) {
          return this.$message.error('请输入发票抬头');
        }
        if (!this.invoiceInfo.email) {
          return this.$message.error('请输入电子邮箱');
        }
      } else {
        for (var i in this.invoiceInfo) {
          if (!this.invoiceInfo[i]) {
            return this.$message.error(this.title_info[i]);
          }
        }
      }
      if (!isEmail(this.invoiceInfo.email)) {
        return this.$message.error('请检查电子邮箱');
      }
      const res = await this.$http.post(order.invoice, {
        order_id: this.billInfo.id,
        invoice: this.invoiceInfo
      });
      if (res.code == 0) {
        // this.orderList[this.index].state = 3;
        this.$message.success('发票开取中，稍后将发送至您的邮箱');
        this.cancelBill();
      }
    },
    downbill(url) {
      window.open(url);
    },
    toPay(item) {
      console.log(item);
      item.numbers = item.number;
      console.info(this.$store.state.projectArr);
      item.name = this.$store.state.projectArr.find(
        items => items.value == item.organization_id
      ).label;
      item.price = item.total_price;
      item.product_id = item.order_items[0].product_id;
      item.projectName = item.order_items[0].name;
      item.recipient = {
        address: ''
      };
      this.$router.push({
        name: 'pay',
        params: {
          info: item
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    const res = await this.$http.get(order.listProduct);
    this.listProduct = res.data;
    this.getOrderList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.listBox {
  width: 1200px;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
  .title {
    font-size: 24px;
    font-weight: 500;
  }
  .item {
    width: 1000px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 60px;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc;
    .content {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }
    .number-title {
      font-weight: 500;
    }
  }
  .borderBot {
    border: none !important;
  }
  .bititle {
    font-size: 16px;
    font-weight: 500;
  }
  .bill {
    height: 30px;
    line-height: 30px;
  }
}
</style>
